import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

export async function generateTeamPdf(name: string, season: string) {
  return new Promise(async (resolve, rejected) => {
    const PDF = new jsPDF({
      // orientation: 'landscape',
      unit: 'pt',
      format: [792, 846],
    })
    await generatePreview(PDF, name, season)
    resolve('success')
  })
}

async function generatePreview(PDF: any, name: string, season: string) {
  const container = document.querySelector('#orders-container')
  const elementHTML = container as HTMLElement
  html2canvas(elementHTML, { allowTaint: true, useCORS: true }).then(
    (canvas) => {
      const imgData = canvas.toDataURL()
      var imgWidth = PDF.internal.pageSize.getWidth()
      var pageHeight = PDF.internal.pageSize.getHeight()
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight
      var position = 0
      PDF.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        PDF.addPage([792, 846])
        pageHeight = 846
        PDF.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
      }
      PDF.save(`PROMO_${season}_NK_ASSORTMENT_${name.toUpperCase()}`)
    },
  )
}
