import React, { useRef, useEffect, useState } from 'react'
import { darkenColor } from 'helpers/designer'
import { ImageLoad, Images } from 'components'
import classes from './horizontal-list.module.scss'
import { createPortal } from 'react-dom'

interface Props {
  type: 'pill' | 'circularPill' | 'color' | 'logo'
  theme?: 'light' | 'dark'
  alignCenter?: boolean
  scroll?: boolean
  label?: string
  list: any[]
  idKey: string
  valueKey: string
  selectedItem?: any
  autoSelect?: boolean
  additionalOptions?: boolean
  onSelect: (e: any, i: number) => void
}

export default function HorizontalList({
  type,
  theme = 'light',
  alignCenter = false,
  scroll = true,
  label = '',
  list,
  idKey,
  valueKey,
  selectedItem = {},
  autoSelect = false,
  additionalOptions = false,
  onSelect,
}: Props) {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [tooltipColorIndex, setTooltipColorIndex] = useState(-1)
  const [tooltipStyles, setTooltipStyles] = useState({})

  useEffect(() => {
    if (list && list.length) {
      const obj = getSelectedObj()

      if (autoSelect) {
        onSelect(obj.item, obj.index)
      }
      scrollToChild(obj.index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  const handleClick = (e: any, listItem: any, index: number) => {
    if (additionalOptions) {
      changeAdditionalOption(listItem, index, 'Left')
      // if (tooltipColorIndex !== index) {
      //   const positionObj = e.target.getBoundingClientRect()
      //   setTimeout(() => {
      //     setTooltipStyles({
      //       left: positionObj.left + 'px',
      //       top: positionObj.top + 'px',
      //     })
      //     setTooltipColorIndex(index)
      //   }, 300)
      // } else {
      //   setTooltipStyles({})
      //   setTooltipColorIndex(-1)
      // }
    } else {
      onSelect(listItem, index)
    }
    if (scroll) {
      scrollToChild(index)
    }
  }

  function changeAdditionalOption(
    listItem: any,
    index: number,
    position: string,
  ) {
    if (!listItem.position) {
      listItem.position = []
    }
    // const positionIndex = listItem.position.indexOf(position)
    // if (positionIndex > -1) {
    //   listItem.position.splice(positionIndex, 1)
    // } else {
    //   list.forEach((colorItem) => {
    //     if (colorItem.position) {
    //       const prevPositionIndex = colorItem.position.indexOf(position)
    //       if (prevPositionIndex > -1) {
    //         colorItem.position.splice(prevPositionIndex, 1)
    //       }
    //     }
    //   })
    // }
    list.forEach((colorItem) => {
      colorItem.position = []
    })
    listItem.position.push('Left', 'Right')
    onSelect(listItem, index)
  }

  const scrollToChild = (index: number) => {
    const elem: any = containerRef.current?.children[index]
    const correction: number = type === 'color' ? 4 : 0

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(
        elem.offsetLeft +
          elem.clientWidth * 0.5 -
          scrollContainerRef.current.clientWidth * 0.5 +
          correction,
        0,
      )
    }
  }

  const getSelectedObj = () => {
    const index = list.findIndex((item: any) => {
      return isItemSelected(item)
    })

    return {
      item: list[index > -1 ? index : 0],
      index: index > -1 ? index : 0,
    }
  }

  const isItemSelected = (item: any) => {
    return (
      item.selected ||
      valueKey
        .split(' || ')
        .find((key) => item[key] && item[key] === selectedItem[key])
    )
  }

  const getSelectedColor = () => {
    const selectedColor: any = list.find((listItem: any) => listItem.selected)

    return (
      <div className={`${classes.listCaption} ${classes[theme]}`}>
        <div className={classes.caption}>
          <div className={classes.label}>{label || 'Color'}:</div>
          <div className={classes.value}>
            {(selectedColor &&
              (selectedColor.publicName || selectedColor.name)) ||
              '--'}
          </div>
        </div>
      </div>
    )
  }

  const getLabel = () => {
    return (
      <div className={`${classes.listCaption} ${classes[theme]}`}>
        <div className={classes.caption}>
          <div className={classes.label}>{label}</div>
        </div>
      </div>
    )
  }

  const getValue = (item: any) => {
    let value = ''

    valueKey.split(' || ').find((key) => {
      value = item[key]
      return !!item[key]
    })

    //to show Jumpman when brand is Jordan
    return item.brand === 'Jordan' ? 'Jumpman' : value
  }

  const getColorStyles = (item: any) => {
    const gradient = ['from 90deg']
    gradient.push('hsla(0, 0%, 0%, 0.3), 10deg')
    if (item.multiColors && item.multiColors.length) {
      item.multiColors.forEach((color: string) => {
        gradient.push(color)
      })
      gradient.push('350deg')
    } else {
      gradient.push(`${item[valueKey]}, 350deg`)
    }
    gradient.push('hsla(0, 0%, 100%, 0.3)')

    const boxShadow = []
    if (theme === 'light') {
      boxShadow.push('0 0 0 3px var(--white-color)')
      boxShadow.push(`0 0 0 5px ${darkenColor(item[valueKey], 50)}`)
    } else if (theme === 'dark') {
      boxShadow.push('0 0 0 3px var(--black-color)')
      boxShadow.push(`0 0 0 5px ${darkenColor(item[valueKey], -20)}`)
    }

    return {
      backgroundImage: `conic-gradient(${gradient.join(', ')})`,
      backgroundColor: item[valueKey],
      boxShadow: isItemSelected(item) && boxShadow.join(', '),
    }
  }

  const renderValue = (item: any) => {
    if (type === 'logo') {
      return <ImageLoad src={getValue(item)} />
    } else if (type === 'color') {
      if (item.icon) {
        return (
          <div
            className={classes.colorIcon}
            style={{
              backgroundImage: `url(${
                Images[item.icon as keyof typeof Images]
              })`,
            }}></div>
        )
      } else {
        return ''
      }
    } else {
      return <div className={classes.label}>{getValue(item)}</div>
    }
  }

  return (
    <div className={classes.container}>
      {type === 'color' && getSelectedColor()}
      {type !== 'color' && label && getLabel()}
      <div
        className={`${classes.listContainer} ${
          alignCenter ? classes.alignCenter : ''
        }  ${scroll ? classes.scroll : ''}`}
        ref={scrollContainerRef}>
        <div
          className={`${classes.list} ${classes[type]} ${classes[theme]}`}
          ref={containerRef}>
          {list.map((listItem: any, index: number) => {
            return (
              <div
                className={`${classes.listItem} ${
                  isItemSelected(listItem) ? classes.active : ''
                }`}
                key={listItem[idKey] + '-' + index}
                style={type === 'color' ? getColorStyles(listItem) : {}}
                onClick={(e) => handleClick(e, listItem, index)}>
                {renderValue(listItem)}
                {tooltipColorIndex === index &&
                  createPortal(
                    <div
                      className={classes.colorOptionsContainer}
                      style={tooltipStyles}></div>,
                    document.body,
                  )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
